import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

export const Base = (props: TextProps) => {
  return <Text fontFamily="Montserrat" {...props} />;
};

export const Normal = (props: TextProps) => {
  return <Text fontFamily="Montserrat" fontSize={"14px"} fontWeight={"500"} lineHeight={"110%"} {...props} />;
};

export const NormalBold = (props: TextProps) => {
  return <Text fontFamily="Montserrat" fontSize={"14px"} fontWeight={"600"} lineHeight={"140%"} {...props} />;
};

export const NormalCaps = (props: TextProps) => {
  return (
    <Text
      fontFamily="Montserrat"
      fontSize={"14px"}
      fontWeight={"600"}
      lineHeight={"17px"}
      letterSpacing="0.05em"
      textTransform="uppercase"
      {...props}
    />
  );
};

export const Small = (props: TextProps) => {
  return <Text fontFamily="Montserrat" fontSize={"12px"} fontWeight={"600"} lineHeight={"110%"} {...props} />;
};

export const Large = (props: TextProps) => {
  return (
    <Text
      fontFamily={"OpenSans"}
      fontSize={{ base: "16px", md: "18px" }}
      fontWeight={"400"}
      lineHeight={"150%"}
      {...props}
    />
  );
};

export const P = (props: TextProps) => {
  return (
    <Text
      fontFamily="Montserrat"
      fontSize={{ base: "18px", md: "17px" }}
      fontWeight={"500"}
      lineHeight="150%"
      letterSpacing="-0.02em"
      {...props}
    />
  );
};

export const H1 = (props: TextProps) => {
  return (
    <Text
      as={"h1"}
      fontFamily="Montserrat"
      fontSize={{ base: "40px", md: "56px" }}
      fontWeight="600"
      lineHeight="110%"
      letterSpacing="-0.03em"
      {...props}
    />
  );
};

export const H3 = (props: TextProps) => {
  return (
    <Text
      as={"h3"}
      fontFamily="Montserrat"
      fontSize={"24px"}
      fontWeight="700"
      lineHeight="130%"
      letterSpacing="0.15em"
      textTransform={"uppercase"}
      {...props}
    />
  );
};

export const H4 = (props: TextProps) => {
  return (
    <Text
      as={"h4"}
      fontFamily={"Montserrat"}
      fontSize={"22px"}
      fontWeight={"600"}
      lineHeight="27px"
      letterSpacing="-0.03em"
      {...props}
    />
  );
};

export const H5M = (props: TextProps) => {
  return <Text as={"h5"} fontFamily={"Montserrat"} fontSize={"16px"} fontWeight={"600"} lineHeight="125%" {...props} />;
};

export const H5OP = (props: TextProps) => {
  return (
    <Text
      as={"h5"}
      fontFamily={"OpenSans"}
      fontSize={"16px"}
      fontWeight={"600"}
      lineHeight="21px"
      letterSpacing={"0em"}
      {...props}
    />
  );
};

export const Cta1 = (props: TextProps) => {
  return (
    <Text
      fontFamily={"Montserrat"}
      fontSize={"16px"}
      fontWeight={"600"}
      lineHeight={"125%"}
      letterSpacing={"0.2em"}
      textTransform="uppercase"
      {...props}
    />
  );
};

export const Description = (props: TextProps) => {
  return (
    <Text
      fontFamily={"Montserrat"}
      fontSize={"17px"}
      fontWeight={"600"}
      lineHeight={"150%"}
      letterSpacing={"-0.03em"}
      {...props}
    />
  );
};

export const S2 = (props: TextProps) => {
  return (
    <Text
      fontFamily={"Montserrat"}
      fontSize={{ base: "12px", md: "14px" }}
      fontWeight={"500"}
      lineHeight="150%"
      letterSpacing={"-0.03em"}
      {...props}
    />
  );
};
